import { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { RestrictAccess } from 'components';
import HDPMenu from 'components/HDPMenu/HDPMenu';
import { submissions as submissionsApi } from 'services/api';
import { issueStatuses } from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { ImsIssue, SubmissionValidation } from 'types/submission';

import styles from './qualityRules.module.scss';

interface IssueMenuItemProps {
    rule: SubmissionValidation;
    isLatestSubmission: boolean;
    anchorEl: HTMLElement | null;
    issueCreateStatus: string;
    setIssueCreateStatus: (status: string) => void;
    handleClickCloseMenu: () => void;
    disallowCreateIssues: boolean;
    index: number;
    submissionId: string;
    isRestrictedUser?: boolean;
}

const IssueMenuItem = (props: IssueMenuItemProps) => {
    const {
        rule,
        isLatestSubmission,
        anchorEl,
        issueCreateStatus,
        setIssueCreateStatus,
        handleClickCloseMenu,
        disallowCreateIssues,
        index,
        submissionId,
        isRestrictedUser = false,
    } = props;
    const [isCreateIssueDisabled, setIsCreateIssueDisabled] =
        useState<boolean>();
    const [isCreateIssueVisible, setIsCreateIssueVisible] = useState<boolean>();
    const [isViewIssueVisible, setIsViewIssueVisible] = useState<boolean>();

    const updateMenuState = (
        issue: ImsIssue | undefined,
        localStatus: string,
        isRestrictedUser: boolean,
        isLatestSubmission: boolean,
        disallowCreateIssues: boolean,
    ) => {
        // If there is a URL for the issue, it means that the issue exists in IMS, so we should show the view issue option
        const issueExistsInIms = issue?.imsIssueUrl !== null;

        if (issue && issueExistsInIms) {
            setIsCreateIssueDisabled(true);
            setIsCreateIssueVisible(true);
            setIsViewIssueVisible(true);
            return;
        }

        if (
            localStatus === issueStatuses.PENDING ||
            localStatus === issueStatuses.CREATED
        ) {
            setIsCreateIssueDisabled(true);
            setIsCreateIssueVisible(true);
            setIsViewIssueVisible(false);
            return;
        }

        setIsCreateIssueDisabled(isRestrictedUser || !isLatestSubmission);
        setIsCreateIssueVisible(true);
        setIsViewIssueVisible(false);

        if (disallowCreateIssues) {
            setIsCreateIssueDisabled(true);
        }
    };

    const handleClickCreateIssue = async () => {
        setIssueCreateStatus(issueStatuses.PENDING);
        handleClickCloseMenu();
        try {
            const results = await submissionsApi.createIssue({
                validationCode: rule.Code,
                regulatorCode: rule.RegulatorCode,
                submissionUuid: submissionId,
            });
            rule.issue = results;

            setIssueCreateStatus(issueStatuses.CREATED);
            setIsCreateIssueDisabled(true);
            setIsCreateIssueVisible(false);
        } catch (error) {
            setIssueCreateStatus(issueStatuses.FAILED);
        }
    };

    useEffect(() => {
        updateMenuState(
            rule.issue,
            issueCreateStatus,
            isRestrictedUser,
            isLatestSubmission,
            disallowCreateIssues,
        );
    }, [
        isRestrictedUser,
        rule.issue,
        issueCreateStatus,
        isLatestSubmission,
        disallowCreateIssues,
    ]);

    return (
        <HDPMenu
            anchorEl={anchorEl}
            id="ims-menu"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClickCloseMenu}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            autoFocus={true}
        >
            <RestrictAccess allowPermissions={['collections.view-issue']}>
                {isViewIssueVisible && (
                    <MenuItem
                        tabIndex={0}
                        data-test-id={formatTestId(
                            'view issue',
                            `quality rule ${index}`,
                        )}
                        // On click, send the click to the child anchor element
                        // to open the issue in a new tab
                        onClick={() => {
                            const anchor = document.querySelector(
                                `a[id="${formatTestId(
                                    'view issue',
                                    `quality rule ${index}`,
                                )}"]`,
                            ) as HTMLElement;
                            anchor?.click();
                        }}
                    >
                        <a
                            href={rule.issue?.imsIssueUrl}
                            target="_blank"
                            id={formatTestId(
                                'view issue',
                                `quality rule ${index}`,
                            )}
                            className={styles.link}
                            rel="noreferrer"
                        >
                            View issue
                        </a>
                    </MenuItem>
                )}
            </RestrictAccess>
            {isCreateIssueVisible && (
                <MenuItem
                    tabIndex={0}
                    disabled={isCreateIssueDisabled}
                    onClick={handleClickCreateIssue}
                    data-test-id={
                        !isCreateIssueDisabled
                            ? formatTestId(
                                  'create issue',
                                  `quality rule ${index}`,
                              )
                            : null
                    }
                >
                    Create issue
                </MenuItem>
            )}
        </HDPMenu>
    );
};

export default IssueMenuItem;

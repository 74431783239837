import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Chip, TableCell } from '@mui/material';
import { RestrictAccess } from 'components';
import HDPLink from 'components/HDPLink/HDPLink';
import HDPMenu from 'components/HDPMenu/HDPMenu';
import { getRegulatorShortName } from 'queries/regulator/utils';
import { useUserContext } from 'src/components/index';
import { PATHS } from 'src/constants/constants';
import {
    calculatePercentage,
    getIssueStatusLabel,
} from 'src/pages/Collections/Submission/utils';
import ThreeDotIconButton from 'src/patterns/ThreeDotIconButton/ThreeDotIconButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { Collection } from 'types/collection';
import { Regulator } from 'types/regulator';
import { SubmissionValidation } from 'types/submission';

import IssueMenuItem from './IssueMenuItem';

import styles from './qualityRules.module.scss';

interface QualityRuleRowProps {
    rule: SubmissionValidation;
    regulators: Regulator[];
    submissionId: string;
    instId: string;
    collection?: Collection;
    collectionId: string;
    reference: string;
    isLatestSubmission: boolean;
    disallowCreateIssues: boolean;
    index: number;
}

const QualityRuleRow = ({
    rule,
    regulators,
    collection,
    instId,
    submissionId,
    isLatestSubmission,
    disallowCreateIssues,
    index,
}: QualityRuleRowProps) => {
    const { collectionId = '', reference = '' } = useParams();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [issueCreateStatus, setIssueCreateStatus] = useState<string>('');
    const { isAdmin, isSpecialCategory, isAnalyst, isProvider, roles } =
        useUserContext();

    const isProviderSubmitterUser = isProvider && roles.includes('submitter');

    const issueStatusLabel = rule.issue
        ? getIssueStatusLabel(rule.issue, issueCreateStatus)
        : '';

    const handleClickOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(e.currentTarget);

    const handleClickCloseMenu = useCallback(
        () => setAnchorEl(null),
        [setAnchorEl],
    );

    const issueMenuProps = useMemo(
        () => ({
            rule,
            isLatestSubmission,
            anchorEl,
            issueCreateStatus,
            setIssueCreateStatus,
            handleClickCloseMenu,
            disallowCreateIssues,
            index,
            submissionId,
        }),
        [
            rule,
            isLatestSubmission,
            anchorEl,
            issueCreateStatus,
            setIssueCreateStatus,
            handleClickCloseMenu,
            disallowCreateIssues,
            index,
            submissionId,
        ],
    );

    return (
        <>
            <TableCell align="left">
                {getRegulatorShortName(rule.RegulatorCode, regulators)}
            </TableCell>
            <TableCell align="left">
                {rule.ConjoinedTolerances}
                {rule.IsProviderToleranceOverride && (
                    <Chip
                        size="small"
                        label="Override"
                        className={styles.overrideChip}
                    />
                )}
            </TableCell>
            <TableCell align="left">{rule.PopulationCount}</TableCell>
            <TableCell align="left" className={styles.error}>
                <HDPLink
                    size="xSmall"
                    customStyles={styles.error}
                    data-test-id={formatTestId('linkTo', `rule ${index}`)}
                    reactRouterProps={{
                        to: generatePath(
                            isAdmin || isSpecialCategory || isAnalyst
                                ? PATHS.ADMIN_QUALITY_RULE
                                : PATHS.QUALITY_RULE,
                            {
                                collectionId:
                                    collection?.id.toString() || collectionId,
                                reference: collection?.reference || reference,
                                instId,
                                submissionId,
                                rule: rule.Code,
                                regulatorCode: rule.RegulatorCode,
                            },
                        ),
                    }}
                >
                    {rule.FailureCount} (
                    {calculatePercentage(
                        rule.FailureCount,
                        rule.PopulationCount,
                    )}
                    %)
                </HDPLink>
            </TableCell>
            <TableCell align="left">
                {issueStatusLabel && (
                    <span className={styles.chip}>{issueStatusLabel}</span>
                )}
            </TableCell>
            <TableCell align="center">
                <>
                    <ThreeDotIconButton
                        aria-label="create-issue-menu-options"
                        aria-haspopup="true"
                        onClick={handleClickOpenMenu}
                        data-test-id={formatTestId(
                            'three dot menu',
                            `row ${index}`,
                        )}
                    />
                </>
                <HDPMenu
                    id="new-issue-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClickCloseMenu}
                    data-test-id={formatTestId(
                        'quality rule options',
                        `row ${index}`,
                    )}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <RestrictAccess
                        allowPermissions={['collections.create-issue']}
                        fallBackComponent={
                            <IssueMenuItem
                                isRestrictedUser={
                                    // Only provider users with the submitter role should be able to access the 'Create issue' option
                                    // This creates a safer fallback for the 'Create issue' option in cases where the 'collections.create-issue' permission is not showing for the user.
                                    // For more info, see the permissions matrix: https://jisc365.sharepoint.com/:x:/r/sites/HESADFWorkStreams/_layouts/15/Doc.aspx?sourcedoc=%7B0A70D07F-E0F8-4D49-98A0-22FB93A6CE5A%7D&file=HDP%20sections%20and%20screens%20-%20for%20user%20permissions%20mapping%20-%20latest.xlsx&action=default&mobileredirect=true&cid=6e31172b-7569-4af1-9e31-14cdb84a065a
                                    !isProviderSubmitterUser
                                }
                                {...issueMenuProps}
                            />
                        }
                    >
                        <IssueMenuItem {...issueMenuProps} />
                    </RestrictAccess>
                </HDPMenu>
            </TableCell>
        </>
    );
};

export default QualityRuleRow;
